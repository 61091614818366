<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <validation-observer v-slot="{ handleSubmit }" ref="VForm">
            <b-form @submit.prevent="handleSubmit(submitLegal)">
              <b-card no-body>
                <template #header>
                  <h5 class="card-title">{{row.asc_name}}</h5>
                </template>
                <b-card-body>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <template #label>Title (ID)</template>
                        <b-form-input v-model="row.al_title_id"></b-form-input>
                        <VValidate name="Title (ID)" v-model="row.al_title_id" :rules="mrValidation.al_title_id" />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <template #label>Title (EN)</template>
                        <b-form-input v-model="row.al_title_en"></b-form-input>
                        <VValidate name="Title (EN)" v-model="row.al_title_en" :rules="mrValidation.al_title_en" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md=6>
                      <b-form-group label="Content (ID)" label-for="desc_id">
                        <QuillEditor id="desc_id" :value.sync="row.al_content_id" />
                        <VValidate name="Content (ID)" v-model="row.al_content_id" :rules="mrValidation.al_content_id" />
                      </b-form-group>
                    </b-col>
                    <b-col md=6>
                      <b-form-group label="Content (EN)" label-for="desc_en">
                        <QuillEditor id="desc_en" :value.sync="row.al_content_en" />
                        <VValidate name="Content (EN)" v-model="row.al_content_en" :rules="mrValidation.al_content_en" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
                <b-card-footer>
                  <div class="text-right">
                    <b-button variant="primary" class="btn-rounded" type="submit">Save Changes</b-button>
                  </div>
                </b-card-footer>
              </b-card>
            </b-form>
          </validation-observer>
        </b-tab>

        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection slug="privacy-policy" />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import QuillEditor from '@/components/QuillEditor'
import SEOSection from '@/components/SEOSection'
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  components: {
    QuillEditor,
    SEOSection
  },
  data() {
    return {
      mrValidation: {},
      itemsToolbar: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'undo',
        'redo'
      ],
      seoPageSetting: {},
      seoValidation: {},
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    submitLegal(){
      this.swalLoading()

      Gen.apiRest("/do/BoLegal", {
        data: {
          type: 'update',
          ...this.row
        }
      }).then(res => {
        this.$swal({
          icon: 'success',
          title: res.data.message
        })
      }).catch(err => {
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Something went wrong!"
        })
      })

    },
    apiGet(params = {}){
      this.loadingOverlay = true
      Gen.apiRest(
        "/get/BoLegal/privacy-policy", 
        {
          params: Object.assign({}, this.apiParams, params.query||{})
        }
      ).then(res=>{
        this.$set(this, 'row', res.data.row)
        this.$set(this, 'mrValidation', res.data.mrValidation)
        this.loadingOverlay = false
      }).catch(()=>{
        this.loadingOverlay = false
      })
    },
  },
  watch: {
    $route() {
      this.apiGet()
    },
        // counter seo
    'seoPageSetting.sss_meta_title_id'(v){
      let el = document.getElementById('Meta Title ID')
      if(el){
        let showcount = document.getElementById('Meta Title ID' + 'count')
        let cErr = document.getElementById('Meta Title ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          el.style.display = "none"
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'seoPageSetting.sss_meta_title_en'(v){
      let el = document.getElementById('Meta Title EN')
      if(el){
        let showcount = document.getElementById('Meta Title EN' + 'count')
        let cErr = document.getElementById('Meta Title EN' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"          
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_id'(v){
      let el = document.getElementById('Meta Description ID')
      if(el){
        let showcount = document.getElementById('Meta Description ID' + 'count')
        let cErr = document.getElementById('Meta Description ID' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_en'(v){
      let el = document.getElementById('Meta Description EN')
      if(el){
        let showcount = document.getElementById('Meta Description EN' + 'count')
        let cErr = document.getElementById('Meta Description EN' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
  }
}
</script>